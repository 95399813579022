import React from 'react';
import { motion } from 'framer-motion';
import { FaUser, FaEnvelope, FaCommentDots } from 'react-icons/fa';

const ContactSection = () => (
  <section id="contact" className="py-20 text-center bg-gray-50">
    <h3 className="text-3xl font-semibold mb-8">Get In Touch</h3>
    <motion.form
      className="max-w-md mx-auto space-y-4 bg-white p-8 rounded-lg shadow-lg"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      {/* Name Field */}
      <div className="relative">
        <FaUser className="absolute left-3 top-3 text-gray-400" />
        <input
          type="text"
          placeholder="Your Name"
          className="w-full pl-10 p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition"
        />
      </div>

      {/* Email Field */}
      <div className="relative">
        <FaEnvelope className="absolute left-3 top-3 text-gray-400" />
        <input
          type="email"
          placeholder="Your Email"
          className="w-full pl-10 p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition"
        />
      </div>

      {/* Message Field */}
      <div className="relative">
        <FaCommentDots className="absolute left-3 top-3 text-gray-400" />
        <textarea
          placeholder="Message"
          className="w-full pl-10 p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition"
        ></textarea>
      </div>

      {/* Submit Button */}
      <motion.button
        type="submit"
        className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:ring-2 focus:ring-blue-300 focus:ring-offset-2 transition"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        Send Message
      </motion.button>
    </motion.form>
  </section>
);

export default ContactSection;
