import React from 'react';
import { motion } from 'framer-motion';

const AboutSection = () => (
  <motion.section
    id="about"
    className="py-20 px-4 bg-white text-center"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0, transition: { duration: 0.8 } }}
  >
    <h3 className="text-2xl font-semibold mb-4">About Us</h3>
    <p className="text-gray-600 max-w-xl mx-auto">
      We are a software company focused on delivering innovative digital experiences.
      Our team of experts specializes in web development, mobile applications, and design.
    </p>
  </motion.section>
);

export default AboutSection;
