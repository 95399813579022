import React from 'react';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import AboutSection from '../components/AboutSection';
import Services from '../components/Services';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';

const Home = () => (
  <div>
    <Header />
    <main className="pt-16">
      <HeroSection />
      <AboutSection />
      <Services/>
      <ContactSection />
    </main>
    <Footer />
  </div>
);

export default Home;
