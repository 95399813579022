import Typewriter from 'typewriter-effect';
import backgroundVideo from '../assets/videos/background.mp4';

const HeroSection = () => (
  <section className="relative flex flex-col items-center justify-center h-screen text-center px-4 overflow-hidden">
    {/* Background Video */}
    <video
      autoPlay
      loop
      muted
      playsInline
      className="absolute inset-0 w-full h-full object-cover -z-10"
    >
      <source src={backgroundVideo} type="video/mp4" />
      Your browser does not support the video tag.
    </video>

    {/* Background Overlay for Better Text Contrast */}
    <div className="absolute inset-0 bg-black opacity-50 -z-10"></div>

    {/* Typewriter Effect Text */}
    <h2 className="text-4xl sm:text-5xl font-bold mb-4 text-white relative z-10">
      <Typewriter
        options={{
          strings: ['Build Your Digital Future', 'Transforming Ideas into Reality'],
          autoStart: true,
          loop: true,
        }}
      />
    </h2>
    <p className="text-gray-200 max-w-md mx-auto mb-8 relative z-10">
      We create seamless, responsive, and scalable digital solutions.
    </p>

    {/* Call-to-Action Button */}
    <a
      href="#services"
      className="bg-blue-500 text-white py-2 px-6 rounded hover:bg-blue-600 relative z-10 transition transform hover:scale-105"
    >
      Our Services
    </a>
  </section>
);

export default HeroSection;
