import React from 'react';

const Header = () => (
  <header className="bg-white/30 backdrop-blur-md py-4 px-8 shadow-sm fixed w-full z-10">
    <nav className="flex justify-between items-center max-w-5xl mx-auto">
      <h1 className="text-lg font-semibold">WebWave</h1>
      <ul className="flex space-x-6 text-sm">
        <li><a href="#about" className="hover:text-blue-500">About</a></li>
        <li><a href="#services" className="hover:text-blue-500">Services</a></li>
        <li><a href="#contact" className="hover:text-blue-500">Contact</a></li>
      </ul>
    </nav>
  </header>
);

export default Header;
