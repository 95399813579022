import React from 'react';
import { FaCode, FaMobileAlt, FaPencilRuler } from 'react-icons/fa';
import { motion } from 'framer-motion';

const servicesData = [
  { title: 'Web Development', icon: <FaCode /> },
  { title: 'Mobile Development', icon: <FaMobileAlt /> },
  { title: 'UI/UX Design', icon: <FaPencilRuler /> },
];

const Services = () => (
  <section id="services" className="p-8 text-center bg-gray-50">
    <h2 className="text-3xl font-semibold mb-8">Our Services</h2>
    <div className="grid md:grid-cols-3 gap-8">
      {servicesData.map((service, index) => (
        <motion.div
          key={index}
          className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2 flex flex-col items-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4, delay: index * 0.2 }}
        >
          <div className="text-blue-500 text-5xl mb-4">
            {service.icon}
          </div>
          <h3 className="text-xl font-medium mb-2">{service.title}</h3>
          <p className="text-gray-600">
            Our team delivers high-quality {service.title.toLowerCase()} solutions to enhance your business.
          </p>
        </motion.div>
      ))}
    </div>
  </section>
);

export default Services;
